import React, { useState } from "react"
import styles from "./CsvUpload.module.scss"
import Input from "common/components/atoms/Input"
import Card from "common/components/atoms/Card"
import Button from "common/components/atoms/Button"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import useCSVFileUpload from "common/hooks/useCSVFileUpload"
import Loader from "common/components/atoms/Loader"
import Modal from "common/components/atoms/Modal"
import Text from "common/components/atoms/Text"
import useError from "common/hooks/useErrors"
import { getErrorMessage } from "utils/helper"
import * as XLSX from "xlsx"
import { useTranslation } from "react-i18next"

const CsvUpload = () => {
  const { t } = useTranslation()
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false)
  const [reportData, setReportData] = useState<InfluencerResult[]>()
  const setError = useError((state) => state.setError)

  const { onPaymentCSVFileUpload, onConfirmReport, isLoading, isConfirming } =
    useCSVFileUpload()

  const validationSchema = yup.object().shape({
    // track: yup
    //   .mixed()
    //   .required("楽曲CSVをアップロードしてください")
    //   .test(
    //     "fileFormat",
    //     "CSVではないファイルを選択しています",
    //     (value: any) => {
    //       return value && value[0] && value[0].type === "text/csv"
    //     }
    //   )
    //   .test(
    //     "fileSize",
    //     "ファイルサイズが10MB以下でないとダメです。",
    //     (value: any) => {
    //       return value && value[0] && value[0].size <= 10 * 1024 * 1024
    //     }
    //   ),
    video: yup
      .mixed()
      .required("ビデオCSVをアップロードしてください")
      .test(
        "fileFormat",
        "CSVではないファイルを選択しています",
        (value: any) => {
          return value && value[0] && value[0].type === "text/csv"
        }
      )
      .test(
        "fileSize",
        "ファイルサイズが10MB以下でないとダメです。",
        (value: any) => {
          return value && value[0] && value[0].size <= 10 * 1024 * 1024
        }
      ),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const convertJsonToExcel = (json: InfluencerResult[], userType = "jp") => {
    try {
      const workbook = XLSX.utils.book_new()

      const mainData = json.map((item) => {
        const { trackReport, videoReport, ...rest } = item
        return rest
      })

      const mainSheet = XLSX.utils.json_to_sheet(mainData)
      XLSX.utils.book_append_sheet(workbook, mainSheet, "Influencer Data")

      let videoSheet: any = []
      let paymentSheet: any = []

      json.forEach((item, index) => {
        if (item.videoReport.length > 0) {
          videoSheet.push(...item.videoReport)
        }
        if (item.paymentReport.length > 0) {
          paymentSheet.push(...item.paymentReport)
        }
      })

      if (videoSheet.length) {
        const videoSheetAllData = XLSX.utils.json_to_sheet(videoSheet)
        XLSX.utils.book_append_sheet(
          workbook,
          videoSheetAllData,
          `Video Report`
        )
      }
      if (paymentSheet.length) {
        const paymentSheetAllData = XLSX.utils.json_to_sheet(paymentSheet)
        XLSX.utils.book_append_sheet(
          workbook,
          paymentSheetAllData,
          `Payment Report`
        )
      }

      XLSX.writeFile(workbook, `influencer-report-${userType}.xlsx`)
    } catch (e) {
      console.log(e)
    }
  }

  const onCSVUpload = (data: any) => {
    try {
      const formData = new FormData()
      // formData.append("track", data.track[0])
      formData.append("video", data.video[0])
      onPaymentCSVFileUpload(formData)
        .then((response) => {
          setReportData([
            ...response.influencerEnReport,
            ...response.influencerJpReport,
          ])
          convertJsonToExcel(response.influencerEnReport, "en")
          convertJsonToExcel(response.influencerJpReport, "jp")
          setConfirmModalOpen(true)
        })
        .catch((e) => setError(getErrorMessage(e?.subStatusCode)))
    } catch (e) {
      console.log(e)
      setError(getErrorMessage("default"))
    }
  }

  const onConfirmDetails = () => {
    console.log(reportData)
    if (reportData) {
      onConfirmReport({ report: reportData })
        .then((response) => {
          if (response) {
            setError("見積書を発行しました", "success")
          }
          setConfirmModalOpen(false)
        })
        .catch((e) => setError(getErrorMessage(e?.subStatusCode)))
    }
  }

  return (
    <>
      <div className={styles.container}>
        <Card>
          {/* <Input
            label={"楽曲CSV"}
            placeholder="楽曲CSV"
            id={"track"}
            inputProps={{
              ...register("track"),
              type: "file",
              accept: ".csv",
            }}
            error={errors?.track?.message}
          /> */}
          <Input
            label={"ビデオCSV"}
            placeholder="ビデオCSV"
            id={"video"}
            inputProps={{
              ...register("video"),
              type: "file",
              accept: ".csv",
            }}
            error={errors?.video?.message}
          />
          <Button onClick={handleSubmit(onCSVUpload)} disabled={isLoading}>
            {isLoading && <Loader />}
            計算開始する
          </Button>
        </Card>
      </div>
      <Modal
        isOpen={isConfirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
      >
        <div className={styles.modalContainer}>
          <Text ta="center">明細書を発行しますか？</Text>
          <Button onClick={onConfirmDetails} disabled={isConfirming}>
            {isConfirming && <Loader />} 発行する
          </Button>
          <Button variant="grey" onClick={() => setConfirmModalOpen(false)}>
            {t("buttons.cancel")}
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default CsvUpload
