import React, { useEffect } from "react"
import useAuthStore from "store/authStore"
import { useTranslation } from "react-i18next"
import { getApp } from "utils/helper"
import { getCookies } from "utils/cookies"
import { useNavigate } from "react-router-dom"
import Alert from "common/components/molecules/Alert"
import "../i18n/config"

function App() {
  const setIsAuthenticated = useAuthStore((state) => state.setIsAuthenticated)
  const setUserType = useAuthStore((state) => state.setUserType)
  const navigate = useNavigate()
  const locale = useAuthStore((state) => state.locale)
  const { i18n, t } = useTranslation()
  const { app: App, domain: subdomain } = getApp()
  const isEnglish = window.location.href.includes("/en/")

  useEffect(() => {
    const subdomains = ["admin", "influencer"]
    if (!subdomains.includes(subdomain)) {
      navigate("/page-not-found")
    }
    if (getCookies("accessToken")) {
      setIsAuthenticated(true)
    }
    setUserType("admin")
  }, [])

  useEffect(() => {
    i18n.changeLanguage(isEnglish ? "en" : "ja")

    document.title = isEnglish ? "Orinas Contract Tool" : "オリナス契約ツール"
  }, [locale, isEnglish, i18n])

  return (
    <div className="App">
      <Alert />
      <App domain={subdomain} />
    </div>
  )
}

export default App
