import React, { useEffect, useState } from "react"
import styles from "./ContractorDetailsCard.module.scss"
import Text from "common/components/atoms/Text"
import Button from "common/components/atoms/Button"
import useInfluencersStore from "store/influencersStore"
import useContractStatus from "common/hooks/useContractStatus"
import useError from "common/hooks/useErrors"
import { getErrorMessage } from "utils/helper"
import usePreviewAdminPdf from "common/hooks/usePreviewAdminPdf"
import { contractStatus } from "utils/constant"
import Loader from "common/components/atoms/Loader"
import UserEditModal from "common/components/molecules/UserEditModal/UserEditModal"
import { useNavigate } from "react-router-dom"

const ContractorDetailsCard = ({
  fetchContractorDetails,
}: {
  fetchContractorDetails: () => void
}) => {
  const contractorDetails = useInfluencersStore(
    (state) => state.currentInfluencer
  )
  const { onStatusUpdate, isLoading: statusLoading } = useContractStatus()
  const navigate = useNavigate()
  const setError = useError((state) => state.setError)
  const { pdfData, isLoading, error } = usePreviewAdminPdf(
    contractorDetails?.status &&
      ["Contracted", "Approval"].includes(contractorDetails?.status)
      ? contractorDetails?.documentId
      : null
  )
  const [status, setStatus] = useState("")
  const [isEditModalOpen, setEditModalOpen] = useState(false)

  useEffect(() => {
    if (error) {
      setError(getErrorMessage(error.subStatusCode))
    }
  }, [pdfData, error, setError])

  const openPdfPreview = () => {
    if (pdfData) {
      const pdfBlob = new Blob([pdfData], { type: "application/pdf" })
      const downloadUrl = URL.createObjectURL(pdfBlob)
      window.open(downloadUrl, "_blank")
      return downloadUrl
    }
  }

  const userDetails = [
    {
      key: "氏名",
      value: `${contractorDetails?.familyName} ${contractorDetails?.firstName}`,
    },
    {
      key: "フリガナ",
      value: `${contractorDetails?.familyNameKana} ${contractorDetails?.firstNameKana}`,
    },
    {
      key: "ステータス",
      value: contractorDetails?.status
        ? contractStatus[contractorDetails?.status]
        : "",
    },
    { key: "メールアドレス", value: contractorDetails?.email },
    {
      key: "メインチャンネルの名前",
      value: contractorDetails?.mainChannelName,
    },
    {
      key: "YouTubeチャンネルのURL",
      value: contractorDetails?.youtubeChannelUrl,
      isLink: true,
    },
    {
      key: "チャンネルID",
      value: contractorDetails?.channelId?.join(", "),
    },
    {
      key: "楽曲名",
      value: contractorDetails?.musicTitle?.join(", "),
    },
    {
      key: "YouTubeショートの投稿頻度",
      value: contractorDetails?.youtubeShortCount,
    },
    {
      key: "事務所に所属してますか？",
      value: contractorDetails?.agency ? "はい" : "いいえ",
    },
    { key: "平均再生回数", value: contractorDetails?.averageViewCounts },
  ]

  const updateContractStatus = (status: string) => {
    onStatusUpdate({ status })
      .then(() => fetchContractorDetails())
      .catch(() => setError(getErrorMessage("default")))
  }

  return (
    <div className={styles.detailCard}>
      <div className={styles.cardHeader}>
        <Text fw="bold" color="white">
          {contractorDetails?.firstName}
        </Text>
        <Button
          style={{ marginInline: "10px" }}
          maxWidth={150}
          onClick={() => navigate(`/profile/${contractorDetails?._id}/invoice`)}
        >
          明細書
        </Button>
        <Button maxWidth={150} onClick={() => setEditModalOpen(true)}>
          編集
        </Button>
      </div>
      {contractorDetails ? (
        <div className={styles.cardContent}>
          <div className={styles.detailBox}>
            <Text fw="bold">法人ですか？</Text>
            <div className={styles.divider} />
            <Text sz="xxs" fw="bold">
              {contractorDetails?.isCompany ? "はい" : "いいえ"}
            </Text>
          </div>
          {contractorDetails.isCompany && (
            <div className={styles.detailBox}>
              <Text fw="bold">法人名</Text>
              <div className={styles.divider} />
              <Text sz="xxs" fw="bold">
                {contractorDetails.companyName}
              </Text>
            </div>
          )}
          {userDetails.map((item, index) => (
            <div key={index} className={styles.detailBox}>
              <Text fw="bold">{item.key}</Text>
              <div className={styles.divider} />
              {item.isLink ? (
                item.value?.map((url) => (
                  <a href={url} key={url}>
                    {url}
                  </a>
                ))
              ) : (
                <Text sz="xxs" fw="bold">
                  {item?.value || ""}
                </Text>
              )}
            </div>
          ))}
          <div className={styles.detailBox}>
            <Text fw="bold">アナリティクス再生回数画面</Text>
            <div className={styles.divider} />
            {contractorDetails?.screenShot ? (
              <img
                src={`${process.env.REACT_APP_AWS_BUCKET_ENDPOINT}${contractorDetails?.screenShot}`}
                alt="screen-shot"
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_AWS_BUCKET_ENDPOINT}${contractorDetails?.screenShot}`,
                    "_blank"
                  )
                }
                className={styles.screenShotImage}
              />
            ) : (
              <Loader />
            )}
          </div>
          <div className={styles.buttonContainer}>
            {contractorDetails.status === "Applying" && (
              <Button
                variant="yellow"
                maxWidth={350}
                onClick={() => {
                  setStatus("confirm")
                  updateContractStatus("confirm")
                }}
                disabled={statusLoading || !!status}
              >
                {statusLoading && status === "confirm" && <Loader />}
                契約を締結する
              </Button>
            )}
            {contractorDetails.status === "Applying" && (
              <Button
                variant="grey"
                maxWidth={350}
                onClick={() => {
                  setStatus("decline")
                  updateContractStatus("decline")
                }}
                disabled={statusLoading || !!status}
              >
                {statusLoading && status === "decline" && <Loader />}
                契約を破棄する
              </Button>
            )}
            {contractorDetails.status === "Approval" && (
              <Button
                maxWidth={350}
                variant="yellow"
                onClick={() => {
                  setStatus("completed")
                  updateContractStatus("completed")
                }}
                disabled={statusLoading || !!status}
              >
                {statusLoading && status === "completed" && <Loader />}
                契約を完了する
              </Button>
            )}
            {pdfData &&
              ["Contracted", "Approval"].includes(contractorDetails.status) && (
                <Button
                  maxWidth={350}
                  variant="yellow"
                  onClick={openPdfPreview}
                  disabled={statusLoading}
                >
                  {isLoading && <Loader />} 契約を確認する
                </Button>
              )}
          </div>
        </div>
      ) : (
        <div className={styles.cardContent}>
          <Loader sz="md" />
        </div>
      )}
      <UserEditModal
        isEditModalOpen={isEditModalOpen}
        closeEditModal={() => setEditModalOpen(false)}
        fetchContractorDetails={fetchContractorDetails}
      />
    </div>
  )
}

export default ContractorDetailsCard
