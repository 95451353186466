import useSWRMutation from "swr/mutation"
import { useAPI } from "./useAPI"

const useCSVFileUpload = () => {
  const { post } = useAPI()

  const sendUploadRequest = async (url: string, { arg }: { arg: any }) => {
    const response: CSVUploadResponse = await post(url, arg)
    return response
  }

  const { trigger, isMutating, error } = useSWRMutation(
    `/admin/payment/upload/csv`,
    sendUploadRequest
  )

  const sendConfirmRequest = async (url: string, { arg }: { arg: any }) => {
    const response: CSVUploadResponse = await post(url, arg)
    return response
  }

  const {
    trigger: onConfirmReport,
    isMutating: isConfirming,
    error: confirmError,
  } = useSWRMutation(`/admin/payment/confirm/report`, sendConfirmRequest)

  return {
    onPaymentCSVFileUpload: trigger,
    onConfirmReport,
    isLoading: isMutating,
    isConfirming,
    confirmError,
    error,
  }
}

export default useCSVFileUpload
